var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _c("div", { staticClass: "kt-subheader kt-grid__item" }, [
      _c("div", { staticClass: "kt-container kt-container--fluid" }, [
        _c("div", { staticClass: "kt-subheader__main" }, [
          _c(
            "h3",
            { staticClass: "kt-subheader__title text-nowrap text-truncate" },
            [
              _c("SVGIcon", {
                staticClass: "mt-2 mr-3",
                attrs: { "hex-color": "#0f88ef", name: "group" },
              }),
              _vm._v(" Student Groups "),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "kt-subheader__toolbar" }, [
          _c("div", { staticClass: "kt-subheader__wrapper mt-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-bold",
                class: {
                  "btn-clean btn-clean-danger": !_vm.showDeleted,
                  "btn-label-danger": _vm.showDeleted,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    _vm.showDeleted = !_vm.showDeleted
                  },
                },
              },
              [_vm._v(" Show Deleted ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-label-primary btn-bold",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    _vm.creatingGroup = true
                  },
                },
              },
              [_vm._v(" New Group ")]
            ),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.creatingGroup
              ? _c("div", { staticClass: "col-xl-4" }, [
                  _c(
                    "div",
                    { staticClass: "kt-portlet kt-portlet--height-fluid" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "kt-portlet__body kt-portlet__body--fit",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "kt-widget kt-widget--project-1" },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-widget__head d-flex" },
                                [
                                  _vm._m(0),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget__toolbar" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              _vm.creatingGroup = false
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "la la-close",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "kt-widget__body" }, [
                                _c("div", { staticClass: "form-group w-100" }, [
                                  _c("label", [_vm._v("Group Name")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newGroup.groupName,
                                        expression: "newGroup.groupName",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "email",
                                      placeholder: "Group Name",
                                    },
                                    domProps: { value: _vm.newGroup.groupName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.newGroup,
                                          "groupName",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", [_vm._v("Group Description")]),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newGroup.groupDescription,
                                        expression: "newGroup.groupDescription",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder:
                                        "Group of all ELA students...",
                                      rows: "3",
                                    },
                                    domProps: {
                                      value: _vm.newGroup.groupDescription,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.newGroup,
                                          "groupDescription",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "kt-widget__footer" }, [
                                _c(
                                  "div",
                                  { staticClass: "kt-widget__wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-widget__section w-100 d-inline-block",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-success btn-bold pull-right",
                                            class: {
                                              "kt-spinner kt-spinner--sm kt-spinner--light":
                                                _vm.saving,
                                            },
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                _vm.saving ||
                                                !_vm.newGroup.groupName,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.createGroup.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Create ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.filteredGroups, function (group, idx) {
              return _c(
                "div",
                {
                  key: `group_${group.studentGroupId}_${idx}`,
                  staticClass: "col-xl-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "kt-portlet kt-portlet--height-fluid" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "kt-portlet__body kt-portlet__body--fit",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "kt-widget kt-widget--project-1" },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-widget__head d-flex" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget__label" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget__media kt-widget__media--m",
                                        },
                                        [
                                          group.isGroupOwner && !group.deleted
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-group",
                                                  staticStyle: {
                                                    "max-width": "45px",
                                                    width: "45px",
                                                    height: "45px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.editGroup(
                                                        group,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "la la-close fa-2x",
                                                  }),
                                                ]
                                              )
                                            : group.isGroupOwner &&
                                              group.deleted
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "btn btn-clean btn-clean-success btn-sm btn-icon btn-icon-md remove-group",
                                                  staticStyle: {
                                                    "max-width": "45px",
                                                    width: "45px",
                                                    height: "45px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.editGroup(
                                                        group,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "la la-undo fa-2x",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-badge kt-badge--lg",
                                              class: {
                                                "kt-badge--primary":
                                                  !group.groupColor,
                                                "group-logo":
                                                  group.isGroupOwner,
                                              },
                                              staticStyle: {
                                                "max-width": "45px",
                                                width: "45px",
                                                height: "45px",
                                              },
                                              style: group.groupColor
                                                ? `background-color: ${group.groupColor}; opacity: 0.85;`
                                                : "",
                                            },
                                            [
                                              group.groupIcon
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "kt-font-xl",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            group.groupIcon
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("i", {
                                                    staticClass: "fa fa-users",
                                                    class: {
                                                      "kt-font-light":
                                                        !group.groupColor ||
                                                        (group.groupColor &&
                                                          ![
                                                            "White",
                                                            "Yellow",
                                                          ].includes(
                                                            group.groupColor
                                                          )),
                                                      "kt-font-dark":
                                                        group.groupColor &&
                                                        ["Yellow"].includes(
                                                          group.groupColor
                                                        ),
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget__info kt-padding-0 kt-margin-l-15",
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "kt-widget__title",
                                              attrs: {
                                                to: {
                                                  name: "StudentGroup",
                                                  params: {
                                                    studentGroupId:
                                                      group.studentGroupId,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(group.groupName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          group.owner
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "kt-widget__desc",
                                                },
                                                [
                                                  _vm._v(
                                                    " Created by " +
                                                      _vm._s(
                                                        `${group.owner.lastName}, ${group.owner.firstName}`
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "kt-widget__body" }, [
                                _c("div", { staticClass: "row mb-3 px-3" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-boldest mr-3",
                                    },
                                    [
                                      _vm._v(
                                        " Marking Period " +
                                          _vm._s(group.markingPeriod || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-boldest",
                                      class: {
                                        "kt-badge--primary":
                                          group.groupType === "manual",
                                        "kt-badge--success":
                                          group.groupType === "auto",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            group.groupType === "manual"
                                              ? "Manual"
                                              : "Auto"
                                          ) +
                                          " Mode "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "kt-widget__text kt-margin-t-0 kt-padding-t-5",
                                    staticStyle: {
                                      height: "120px",
                                      "max-height": "120px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(group.groupDescription || "") +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "kt-widget__footer",
                                  style: group.groupColor
                                    ? `background-color: ${group.groupColor}; opacity: 0.85;`
                                    : "",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget__wrapper" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget__section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "kt-widget__blog" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-chalkboard-teacher",
                                                class: {
                                                  "kt-font-light":
                                                    group.groupColor &&
                                                    ![
                                                      "White",
                                                      "Yellow",
                                                    ].includes(
                                                      group.groupColor
                                                    ),
                                                  "kt-font-dark":
                                                    group.groupColor &&
                                                    ["Yellow"].includes(
                                                      group.groupColor
                                                    ),
                                                },
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "kt-widget__value kt-font-brand kt-font-boldest",
                                                  class: {
                                                    "kt-font-light":
                                                      group.groupColor &&
                                                      ![
                                                        "White",
                                                        "Yellow",
                                                      ].includes(
                                                        group.groupColor
                                                      ),
                                                    "kt-font-dark":
                                                      group.groupColor &&
                                                      ["Yellow"].includes(
                                                        group.groupColor
                                                      ),
                                                  },
                                                  staticStyle: {
                                                    cursor: "default",
                                                  },
                                                  attrs: { href: "#" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(group.staff.length) +
                                                      " Staff"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "kt-widget__blog" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-graduation-cap",
                                                class: {
                                                  "kt-font-light":
                                                    group.groupColor &&
                                                    ![
                                                      "White",
                                                      "Yellow",
                                                    ].includes(
                                                      group.groupColor
                                                    ),
                                                  "kt-font-dark":
                                                    group.groupColor &&
                                                    ["Yellow"].includes(
                                                      group.groupColor
                                                    ),
                                                },
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "kt-widget__value kt-font-brand kt-font-boldest",
                                                  class: {
                                                    "kt-font-light":
                                                      group.groupColor &&
                                                      ![
                                                        "White",
                                                        "Yellow",
                                                      ].includes(
                                                        group.groupColor
                                                      ),
                                                    "kt-font-dark":
                                                      group.groupColor &&
                                                      ["Yellow"].includes(
                                                        group.groupColor
                                                      ),
                                                  },
                                                  staticStyle: {
                                                    cursor: "default",
                                                  },
                                                  attrs: { href: "#" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      group.students.length
                                                    ) + " Students"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget__button" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-icon",
                                              class: {
                                                "btn-light":
                                                  group.groupColor &&
                                                  !["White", "Yellow"].includes(
                                                    group.groupColor
                                                  ),
                                                "btn-dark":
                                                  group.groupColor &&
                                                  ["Yellow"].includes(
                                                    group.groupColor
                                                  ),
                                                "btn-clean":
                                                  !group.groupColor ||
                                                  (group.groupColor &&
                                                    ["White"].includes(
                                                      group.groupColor
                                                    )),
                                              },
                                              attrs: {
                                                to: {
                                                  name: "StudentGroup",
                                                  params: {
                                                    studentGroupId:
                                                      group.studentGroupId,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-pencil-alt",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget__label" }, [
      _c("div", { staticClass: "kt-widget__info" }, [
        _c(
          "a",
          {
            staticClass: "kt-widget__title",
            staticStyle: { cursor: "default" },
            attrs: { href: "#" },
          },
          [_vm._v(" New Student Group ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }