<template>
<div :key="`key_${key}`">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title text-nowrap text-truncate">
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mt-2 mr-3"
                        name="group"
                    />
                    Student Groups
                </h3>
            </div>
            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper mt-1">
                    <button
                        type="button"
                        class="btn btn-bold"
                        :class="{'btn-clean btn-clean-danger': !showDeleted, 'btn-label-danger': showDeleted}"
                        @click.stop.prevent="showDeleted = !showDeleted"
                    >
                        Show Deleted
                    </button>
                    <button
                        type="button"
                        class="btn btn-label-primary btn-bold"
                        @click.stop.prevent="creatingGroup = true"
                    >
                        New Group
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div
                v-if="creatingGroup"
                class="col-xl-4"
            >
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <div class="kt-widget kt-widget--project-1">
                            <div class="kt-widget__head d-flex">
                                <div class="kt-widget__label">
                                    <div class="kt-widget__info">
                                        <a
                                            href="#"
                                            class="kt-widget__title"
                                            style="cursor: default;"
                                        >
                                            New Student Group
                                        </a>
                                    </div>
                                </div>
                                <div class="kt-widget__toolbar">
                                    <button
                                        type="button"
                                        class="btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md"
                                        @click.stop.prevent="creatingGroup = false"
                                    >
                                        <i class="la la-close" />
                                    </button>
                                </div>
                            </div>
                            <div class="kt-widget__body">
                                <div class="form-group w-100">
                                    <label>Group Name</label>
                                    <input
                                        v-model="newGroup.groupName"
                                        type="email"
                                        class="form-control"
                                        placeholder="Group Name"
                                    >
                                </div>
                                <div class="form-group">
                                    <label>Group Description</label>
                                    <textarea
                                        v-model="newGroup.groupDescription"
                                        class="form-control"
                                        placeholder="Group of all ELA students..."
                                        rows="3"
                                    />
                                </div>
                            </div>
                            <div class="kt-widget__footer">
                                <div class="kt-widget__wrapper">
                                    <div class="kt-widget__section w-100 d-inline-block">
                                        <button
                                            type="button"
                                            class="btn btn-success btn-bold pull-right"
                                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                            :disabled="saving || !newGroup.groupName"
                                            @click.stop.prevent="createGroup"
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-for="(group, idx) in filteredGroups"
                :key="`group_${group.studentGroupId}_${idx}`"
                class="col-xl-4"
            >
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <div class="kt-widget kt-widget--project-1">
                            <div class="kt-widget__head d-flex">
                                <div class="kt-widget__label">
                                    <div class="kt-widget__media kt-widget__media--m">
                                        <span
                                            v-if="group.isGroupOwner && !group.deleted"
                                            class="btn btn-clean btn-clean-danger btn-sm btn-icon btn-icon-md remove-group"
                                            style="max-width: 45px; width: 45px; height: 45px;"
                                            @click.stop.prevent="editGroup(group, true)"
                                        >
                                            <i class="la la-close fa-2x" />
                                        </span>
                                        <span
                                            v-else-if="group.isGroupOwner && group.deleted"
                                            class="btn btn-clean btn-clean-success btn-sm btn-icon btn-icon-md remove-group"
                                            style="max-width: 45px; width: 45px; height: 45px;"
                                            @click.stop.prevent="editGroup(group, false)"
                                        >
                                            <i class="la la-undo fa-2x" />
                                        </span>
                                        <div
                                            class="kt-badge kt-badge--lg"
                                            :class="{'kt-badge--primary': !group.groupColor, 'group-logo': group.isGroupOwner}"
                                            style="max-width: 45px; width: 45px; height: 45px;"
                                            :style="group.groupColor ? `background-color: ${group.groupColor}; opacity: 0.85;` : ''"
                                        >
                                            <span v-if="group.groupIcon" class="kt-font-xl">
                                                {{ group.groupIcon }}
                                            </span>
                                            <i
                                                v-else
                                                class="fa fa-users"
                                                :class="{
                                                    'kt-font-light': !group.groupColor || (group.groupColor && !['White', 'Yellow'].includes(group.groupColor)),
                                                    'kt-font-dark': group.groupColor && ['Yellow'].includes(group.groupColor),
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="kt-widget__info kt-padding-0 kt-margin-l-15">
                                        <router-link
                                            :to="{name: 'StudentGroup', params: { studentGroupId: group.studentGroupId }}"
                                            class="kt-widget__title"
                                        >
                                            {{ group.groupName }}
                                        </router-link>
                                        <span
                                            v-if="group.owner"
                                            class="kt-widget__desc"
                                        >
                                            Created by {{ `${group.owner.lastName}, ${group.owner.firstName}` }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-widget__body">
                                <div class="row mb-3 px-3">
                                    <span class="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-boldest mr-3">
                                        Marking Period {{ group.markingPeriod || '-' }}
                                    </span>
                                    <span
                                        class="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-boldest"
                                        :class="{'kt-badge--primary': group.groupType === 'manual', 'kt-badge--success': group.groupType === 'auto'}"
                                    >
                                        {{ group.groupType === 'manual' ? 'Manual' : 'Auto' }} Mode
                                    </span>
                                </div>
                                <span class="kt-widget__text kt-margin-t-0 kt-padding-t-5" style="height: 120px; max-height: 120px;">
                                    {{ group.groupDescription || '' }}
                                </span>
                            </div>
                            <div class="kt-widget__footer" :style="group.groupColor ? `background-color: ${group.groupColor}; opacity: 0.85;` : ''">
                                <div class="kt-widget__wrapper">
                                    <div class="kt-widget__section">
                                        <div class="kt-widget__blog">
                                            <i
                                                class="fa fa-chalkboard-teacher"
                                                :class="{
                                                    'kt-font-light': group.groupColor && !['White', 'Yellow'].includes(group.groupColor),
                                                    'kt-font-dark': group.groupColor && ['Yellow'].includes(group.groupColor),
                                                }"
                                            />
                                            <a
                                                href="#"
                                                class="kt-widget__value kt-font-brand kt-font-boldest"
                                                :class="{
                                                    'kt-font-light': group.groupColor && !['White', 'Yellow'].includes(group.groupColor),
                                                    'kt-font-dark': group.groupColor && ['Yellow'].includes(group.groupColor),
                                                }"
                                                style="cursor: default;"
                                            >{{ group.staff.length }} Staff</a>
                                        </div>
                                        <div class="kt-widget__blog">
                                            <i
                                                class="fa fa-graduation-cap"
                                                :class="{
                                                    'kt-font-light': group.groupColor && !['White', 'Yellow'].includes(group.groupColor),
                                                    'kt-font-dark': group.groupColor && ['Yellow'].includes(group.groupColor),
                                                }"
                                            />
                                            <a
                                                href="#"
                                                class="kt-widget__value kt-font-brand kt-font-boldest"
                                                :class="{
                                                    'kt-font-light': group.groupColor && !['White', 'Yellow'].includes(group.groupColor),
                                                    'kt-font-dark': group.groupColor && ['Yellow'].includes(group.groupColor),
                                                }"
                                                style="cursor: default;"
                                            >{{ group.students.length }} Students</a>
                                        </div>
                                    </div>
                                    <div class="kt-widget__button">
                                        <router-link
                                            :to="{name: 'StudentGroup', params: { studentGroupId: group.studentGroupId }}"
                                            class="btn btn-icon"
                                            :class="{
                                                'btn-light': group.groupColor && !['White', 'Yellow'].includes(group.groupColor),
                                                'btn-dark': group.groupColor && ['Yellow'].includes(group.groupColor),
                                                'btn-clean': !group.groupColor || (group.groupColor && ['White'].includes(group.groupColor)),
                                            }"
                                        >
                                            <i class="fa fa-pencil-alt" />
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Types from '../store/Types';
import teacherMixins from '../store/mixins/teacherMixins';
import * as network from '../network';

export default {
    name: 'StudentGroups',
    components: {

    },
    mixins: [teacherMixins],
    data() {
        return {
            key: 0,
            filter: '',
            creatingGroup: false,
            showDeleted: false,
            saving: false,
            newGroup: {
                groupName: '',
                groupDescription: '',
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId;
        },
        teachers() {
            return this.$_teacherMixins_getTeachers();
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods
                .filter((x) => !x.deleted)
                .map((x) => ({ ...x }));
        },
        studentGroups() {
            return this.$store.state.database.studentGroups;
        },
        formattedGroups() {
            const { studentGroups, teachers, markingPeriods } = this;
            return studentGroups.map((g) => {
                const group = { ...g };
                const { schoolStaffId } = group;
                group.owner = teachers.find((t) => t.schoolStaffId == schoolStaffId) || null;
                const markingPeriod = markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == group.schoolTermMarkingPeriodId) || null;
                group.markingPeriod = markingPeriod ? markingPeriod.markingPeriod : null;
                return group;
            });
        },
        filteredGroups() {
            const { formattedGroups, showDeleted, filter } = this;
            return formattedGroups.filter((g) => {
                if ((!showDeleted && g.deleted) || (showDeleted && !g.deleted)) return false;
                if (!filter) return true;
                return g.groupName.toLowerCase().indexOf(filter.toLowerCase()) > -1;
            }).sort((a, b) => moment(b.created).diff(moment(a.created)));
        },
    },
    watch: {
        studentGroups() {
            this.key += 1;
        },
    },
    mounted() {
    },
    methods: {
        saveGroupOrdering() {
            const v = this;
            const {
                filteredGroups, showDeleted, filter, user,
            } = v;
            if (v.saving || showDeleted || filter) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;

            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: {
                    staff: filteredGroups.map((g, idx) => {
                        const {
                            studentGroupId, groupName, groupIcon, canEditGroup, groupColor,
                        } = g;

                        return {
                            studentGroupId,
                            schoolStaffId,
                            canEditGroup,
                            groupName,
                            groupIcon: groupIcon || null,
                            groupColor: groupColor || null,
                            groupIndex: idx,
                            deleted: false,
                        };
                    }),
                },
            };

            network.studentGroups.saveGroupStaff(params, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, filteredGroups.map((g, idx) => ({ ...g, groupIndex: idx })));
            });
        },
        createGroup() {
            const v = this;
            const { newGroup, user, schoolTermMarkingPeriodId } = v;
            if (v.saving || !newGroup.groupName) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: { studentGroup: { ...newGroup, schoolTermMarkingPeriodId } },
            };

            network.studentGroups.createGroup(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { studentGroups } = res;
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, studentGroups);
                v.creatingGroup = false;
                v.newGroup = {
                    groupName: '',
                    groupDescription: '',
                };
            });
        },
        editGroup(group, deleted) {
            const v = this;
            if (v.saving || !group || !group.isGroupOwner) return;
            v.saving = true;

            const { user } = v;
            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            const {
                studentGroupId, schoolTermMarkingPeriodId, groupDescription, groupType,
            } = group;

            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: {
                    studentGroup: {
                        studentGroupId,
                        schoolTermMarkingPeriodId,
                        groupDescription,
                        groupType,
                        deleted,
                    },
                },
            };

            network.studentGroups.editGroup(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const groupChanges = res.studentGroups[0];
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GROUPS, [{ ...group, ...groupChanges }]);
            });
        },
    },
};
</script>

<style scoped>
.remove-group {
    display: none !important;
}
.kt-widget__media:hover .remove-group {
    display: flex !important;
}
.kt-widget__media:hover .group-logo {
    display: none !important;
}
</style>
